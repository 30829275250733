










































































































































































import Vue from 'vue';
import FormSwitch from '@/components/form/form-switch.vue';
import { CASE_STATUS } from '@/constant/constants';
import popUpModal from '@/components/form/pop-up-modal.vue';
import errorMessage from '@/components/form/error-message.vue';

export default Vue.extend({
  name: 'goods-insurance',
  props: ['assesment', 'isAPIObserver', 'caseStatus', 'AMSAPISendStatus'],
  components: {
    FormSwitch,
    popUpModal,
    errorMessage,
  },
  data: () => ({
    scope: '',
    moreThanFive: false,
    noOfVehicles: 0,
    modalOpen: false,
    modalTitle: '',
    modalBody: '',
    componentLoaded: false,
    scopeSelected: true,
    noOfVehiclesSelected: true,
  }),
  computed: {
    changeData(): any {
      return [this.moreThanFive, this.noOfVehicles, this.scope].join();
    },
    isDisableAll(): boolean {
      if (this.isAPIObserver) {
        return true;
      }
      const result =
        this.caseStatus === CASE_STATUS.CONTRACT ||
        this.caseStatus === CASE_STATUS.SUGGESTION ||
        this.caseStatus === CASE_STATUS.CONTRACT_NO_SIGNATURE || this.AMSAPISendStatus !== null ||
        this.caseStatus === CASE_STATUS.OFFER_REQUEST_SEND;

      if (result) {
        this.disableAllInputs();
      }
      return result;
    },
  },
  watch: {
    changeData: {
      handler(val) {
        if (this.componentLoaded) this.firstPageDataChange();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    firstPageDataChange() {
      this.$emit(
        'first-page-data-changed',
      );
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    disableAllInputs() {
      const inputs = document.querySelectorAll('input');
      for (let i = 0; i < inputs.length; i += 1) {
        inputs[i].disabled = true;
      }

      const selects = document.querySelectorAll('select');
      for (let i = 0; i < selects.length; i += 1) {
        selects[i].disabled = true;
      }
    },
    scrollToFirstErrorElement() {
      const el = this.$el.getElementsByClassName('red-border')[0];
      if (el) {
        el.scrollIntoView();
      }
    },
    onSubmit() {
      if (this.scope === '') {
        this.scopeSelected = false;
        return;
      }
      if (this.noOfVehicles === 0) {
        this.noOfVehiclesSelected = false;
        setTimeout(() => this.scrollToFirstErrorElement(), 500);
        return;
      }
      const data = {
        AnzahlFahrzeuge: this.noOfVehicles,
        Geltungsbereich: this.scope,
        Ausstellungen: this.moreThanFive,
        BetriebsartHandwerker: this.assesment.operatingMode.BART_TEXT,
      };

      const formData = {
        scope: this.scope,
        moreThanFive: this.moreThanFive,
        noOfVehicles: this.noOfVehicles,
      };
      console.log(data, formData, '2333333333333333333333');
      this.$emit('data-changed', 'c10b0299-e57f-4df7-82b9-f77c560f5bc3', data, formData);
    },
    setValues(formData: any) {
      if (formData.insuranceData.scope) {
        this.scope = formData.insuranceData.scope;
      }
      if (formData.insuranceData.moreThanFive) {
        this.moreThanFive = formData.insuranceData.moreThanFive;
      }
      if (formData.insuranceData.noOfVehicles) {
        this.noOfVehicles = formData.insuranceData.noOfVehicles;
      }
    },
  },
  mounted() {
    if (this.isDisableAll) {
      this.disableAllInputs();
    }
    if (this.$attrs.formData) {
      this.setValues(this.$attrs.formData);
    }
  },
  beforeUpdate() {
    this.componentLoaded = true;
  },
});
